.home-banner {
    height: 450px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFF;
}

.home-banner-title {
    font-family: Roboto;
    letter-spacing: 1.3px;
    font-weight: 900;
    font-size: 25px;
    /* margin-bottom: 20px; */
}

.home-banner-caption {
    margin-top: 10px;
    margin-bottom: 30px;
    color: #dadada;
    font-size: 18px;
    font-weight: 600;
}

.home-banner-mode {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #fff;
    font-size: 14px;
    font-family: HelveticaNeue, arial;
    font-weight: bold;
    cursor: pointer;
}

.hrr-container {}

.hrr-content {
    margin-top: 20px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    /* background-color: red; */
}

.hrr-content>.adjuster-item {
    width: 44.8%;
    margin: 1%;
    cursor: pointer;
}


@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .hrr-content>.adjuster-item {
        width: 100%;
        margin: 10px 0%;
    }
}