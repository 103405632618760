body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section {
  position: relative;
  min-height: 100vh;
}

.padder {
  padding: 2vh 4vw;
}

.text-white {
  color: #FFF;
}


.row {
  display: flex;
  flex-direction: row;
}

.height100 {
  min-height: 100vh;
}


.height80 {
  min-height: 80vh;
}

.height60 {
  min-height: 60vh;
}


.height40 {
  min-height: 40vh;
}


hr {
  border-top: 1px solid #717171 !important;
}

.hr2 {
  border-top: 1px solid #c4c4c4 !important;
}

.column {
  display: flex;
  flex-direction: column;
}


.center {
  position: relative !important;
  justify-content: center;
  align-items: center;
  margin: unset !important;
}

.center-vertical {
  align-items: center;
}

.center-horizontal {
  justify-content: center;
}

.pd-top {
  margin-top: 30px !important;
}

a {
  text-decoration: none;
  color: #000;
}

.MuiFormControl-root {
  width: 100%;
  margin: 5px 0px !important;
}

.MuiFormControl-root, .MuiInputLabel-root, .MuiInput-root, .MuiFormHelperText-root {
  font-family: 'Montserrat' !important;

}

.MuiInput-root:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}


.modal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 99;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5);
  /* Black w/ opacity */
}


.modal-close {
  font-size: 30px;
  font-weight: 500;
  cursor: pointer;
}


.modal-body {
  padding: 20px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.ml-0 {
  margin-left: 0px;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-0 {
  margin-right: 0px;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}



.primary-text {
  font-size: 15px;
  font-weight: bold;
  color: #000 !important;
}

.red-text {
  font-size: 15px;
  font-weight: bold;
  color: red !important;
}

.secondary-text {
  font-size: 15px;
  font-weight: bold;
  color: #0055FD !important;
}

.primary-dark-text {
  color: #3e0f9c !important;
}

.btn {
  background-color: #000;
  font-size: 16px;
  padding: 12px 20px;
  color: white;
  display: block;
  font-weight: 900;
  text-decoration: none;
  border-radius: 40px;
  cursor: pointer;
  transition: 0.4s;
  position: relative;
}

.btn-sm {
  padding: 10px 0px;
  font-size: 14px;
}

.btn-block {
  width: 100%;
}

.btn-block2 {
  width: 80%;
}

.btn-white {
  background-color: #FFF;
  color: #000;
}

.btn-social {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  margin-top: -2px;
  height: 15px;
  margin-right: 4px;
}

.btn-social:hover {
  background-color: #0055FD !important;
  color: #FFF;
}

.btn:hover {
  background-color: #282828;
}

.crud-container {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 2px -1px 10px 2px;
  padding: 30px;
}

.datatable-container {}

.datatable-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datatable-header>a {
  /* min-width: 20%; */
  padding: 10px 20px;
}


.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.text-danger {
  color: #DC3545;
}

.text-warning {}

.crud-goback {
  font-size: 14px;
}

.hsb-outer-container {
  display: flex;
  flex-direction: row;

}

.hsb-container {
  background-color: #FFF;
  padding: 15px 20px;
  width: 310px;
  border-radius: 50px;
}

.hsb-container>i {
  color: #646464;
  font-size: 16px;
  margin-right: 10px;
}

.hsb-container>input, .hsb-container>select {
  outline: none;
  border: none;
  font-size: 16px;
  font-family: Montserrat;
  width: 89%;

}

.btn-hsb {
  height: 28px;
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* .hsb-select-container{
  max-width: 370px;
  flex-wrap: wrap;
} */

.hj-container {
  padding-top: 80px;
}

.hj-caption {
  margin-top: 10px;
  margin-bottom: 30px;
  color: #5b5b5b;
  font-size: 18px;
  font-weight: 600;
}


.hj-content {
  width: 75%;
  display: flex;
  justify-content: center;
  margin-top: 0px 60px;
}

.hj-item {
  margin-top: 30px;
  flex: 1;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 50px; */
}

.hj-item>i {
  font-size: 90px;
  color: #0555FD;
}

.hj-item>span {
  margin-top: 40px;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
}

.hj-btn {
  margin-top: 60px;
  margin-bottom: 80px;
}


.adjusters-container {
  width: 70%;
}

.adjuster-item {
  padding: 30px 20px;
  background: rgb(247, 247, 247);
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}

.a-rating-container {
  flex: 0.09;
  align-items: center;
  justify-content: start;
  margin-top: -10px;
}

.a-rating-box {
  /* background: rgb(127, 246, 195); */
  width: 72px;
  height: 64px;
}

.a-rating {
  font-size: 32px;
  font-family: Poppins, sans-serif;
  font-weight: 900;
}

.a-rating-heading {
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.a-name-container {
  flex: 1;
  padding: 0px 20px;
  justify-content: center;
  align-items: start;
}

.a-name {
  font-size: 20px;
  max-width: 500px;
  text-align: left;
  width: 100%;
  font-family: Poppins, sans-serif;
  font-weight: 900;
}

.a-company-name {
  /* position: absolute; */
  right: 18px;
  /* text-align: right; */
  top: 18px;
}

.a-state-container {
  flex: 0.3;
  justify-content: end;
}

.a-state-name {
  font-size: 13px;
  text-align: right;
}

.cursor-pointer {
  cursor: pointer;
}

.text-sm {
  font-size: 12px;
}

.pdropdown-container.createnew {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pdropdown-container.createnew>div {}

.pdropdown-container.createnew>a {
  margin-top: 15px !important;
  margin-left: 5px !important;
  background-color: #0055FD;
  color: #fff;
  height: 23px;
  width: 23px;
  border-radius: 100px;
  font-size: 12px;
  cursor: pointer;
}


@media only screen and (min-device-width: 350px) and (max-width: 769px) {
  .hsb-outer-container {
    flex-direction: column;
  }

  .btn-hsb {
    margin-top: 10px;
  }

  .adjusters-container {
    width: 100%;
  }

  .search-bar {
    width: 82vw;
  }

  .a-state-name {
    position: absolute;
    top: 15px;
  }

  .a-name-container {
    flex: 2;
  }

  .a-company-name {
    font-size: 14px;
  }


  .p-rating-item {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    position: relative;
  }

  .p-rating-item>.a-state-container {
    position: absolute;
    top: 0px;
    right: 15px;
  }

  .p-rating-item-name {
    padding: 0px;
    margin-top: 10px;
  }

  /* .hsb-container {
    width: 95%;
  } */

  .hsb-container>input {
    width: 80%;
  }
}