.header-nav {
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.header-logo {
    height: 40px;
    width: auto;
}

.header-button-container {
    display: flex;

}

.header-login {
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

.header-signup {
    margin-left: 20px !important;
}

.nav-dropdown {
    position: absolute;
    height: 124px;
    /* background-color: red; */
    width: 130px;
    top: 50px;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 2px -1px 10px 2px;


    display: flex;
    flex-direction: column;
    z-index: 999;
    background-color: #fff;
}

.nav-dropdown>a, .nav-dropdown>span {
    padding: 7px 10px;
    font-size: 14px;
    font-weight: bold;
    transition: 0.2s;
    /* background-color: red; */
}

.nav-dropdown>a:hover, .nav-dropdown>span:hover {
    background-color: #0055FD;
    color: #fff;
}

.nav-dropdown>a:first-child:hover {
    border-radius: 8px 8px 0px 0px;
}

.nav-dropdown>span:hover {
    border-radius: 0px 0px 8px 8px;
}