.r-norating-found {}

.r-norating-found>i {
    font-size: 80px;
    margin-bottom: 20px;
}

.r-norating-found>span {
    width: 420px;
}


@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .r-norating-found>h4 {
        width: 90%;
    }

    .r-norating-found>span {
        width: 90%;
        font-size: 12px;
        margin-top: -10px;
    }


}