.ap-container {}

.ap-detail-content {
    display: flex;
}

.ap-detail-item {
    flex: 1;
}

.ap-rating-form {
    flex: 2.5;
}

.ap-rating-display {
    position: relative;
}

.ap-rating-v {
    font-size: 80px;
    margin-right: 11px;
    font-family: Poppins, sans-serif;
    font-weight: 900;
}

.ap-rating-total {
    position: absolute;
    color: rgb(152, 152, 152);
    font-size: 18px;
    font-weight: 700;
    top: 30px;
}

.ap-rating-caption {
    font-size: 14px;
}

.ap-rating-caption>a {
    margin: 0px 3px;
    font-family: HelveticaNeue, arial;
    font-weight: bold;
    text-decoration: underline;
}

.ap-company-name {
    font-size: 30px;
    hyphens: auto;
    overflow-wrap: break-word;
    padding-bottom: 10px;
    word-break: break-word;
    font-family: Poppins, sans-serif;
    font-weight: bolder;
}

.ap-rating-form>.padder>.crud-container>.padder {
    padding: 30px 0px;
}


.ap-rating-form>.padder>.crud-container>.hj-container>.hj-content {
    width: 100%;
}

.ap-rating-form>.padder>.crud-container>.hj-container>.hj-btn {
    margin-bottom: 0px;
}

.ap-ratings {
    width: 95%;
}

.ap-rating-form-content {}

.ap-rating-form-content>.rating-container>i {
    font-size: 24px;
}

.ap-rating-review-container {}

.ap-rating-review {
    width: 100%;
    padding: 10px;
}

.ap-rating-guide-container {
    background: rgb(247, 247, 247);
    border-radius: 3px;
    padding: 15px 22px;
}

.ap-guide-bullets {
    padding-inline-start: 15px;
    font-size: 14px;
}

.ap-rating-review-container>.column>button {
    width: 300px !important;
    margin-bottom: 10px;
}

.ap-terms {
    width: 60%;
}

.ap-rating-done {}

.ap-rating-done>i {
    font-size: 80px;
}

.ap-review-text {
    font-family: HelveticaNeue, arial;
    font-weight: 200;
    font-size: 14px;
    line-height: 1.5;
    margin: 5px 0px;
}

.ap-review-user-text {
    font-weight: 600;
}

.a-state-container-company {
    justify-content: space-between;
    align-items: end;
}

.btn-response {
    width: 100px;
}

.company-main-item {}

.a-response-container {
    align-items: end;
}

.a-response-content {
    width: 90%;
}

.a-reply-input-container {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.a-reply-input {
    width: 100%;
}

.a-reply-container {}

.a-reply-content {}

.a-reply-profile-image {
    border-radius: 100%;
}

.ap-additional-info-container {
    margin-top: 0px;
    padding-top: -20px !important;
    padding-right: 10px;
    height: 350px;
    overflow-y: auto;
    
}

.ap-additional-info-container::-webkit-scrollbar {
    width: 0.5em;
}

.ap-additional-info-container::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.ap-additional-info-container::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 10px;
    transition: 0.5s;
    /* outline: 1px solid slategrey; */
}

.ap-additional-info-container::-webkit-scrollbar-thumb:hover {
    background-color: #949494;
}

@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .ap-detail-content {
        flex-direction: column;
    }

    .ap-rating-form {
        margin-top: 40px;
    }

    .ap-rating-form>.padder>.crud-container>.hj-container>.hj-content>.hj-item>.fa-solid {
        font-size: 60px;
    }

    .ap-rating-form>.padder>.crud-container>.hj-container>.hj-content>.hj-item>span {
        font-size: 10px;
    }

    .ap-rating-form>.padder>.crud-container>.hj-container>.hj-btn {
        margin-top: 30px;
    }

    .crud-container {
        padding: 10px;
    }

    .ap-rating-form>.padder {
        padding: 0px;
    }

    .ap-terms {
        width: 80%;
    }

    .adjusters-container {
        width: 100%;
    }

    .ap-rating-form-content {
        padding: 50px 0px;
    }

    .ap-ratings {
        margin-top: 15%;
    }

    .ap-rating-review {
        width: 94%;
    }

    .a-reply-trash {
        position: absolute;
        top: 30px;
        right: 0px;
    }

    .btn-reply-mobile {
        position: absolute;
        top: 45px;
        right: -5px;
    }
}