.search-bar {
    border: 1px solid #c4c4c4;
    width: 100% !important;

}

.search-container {}

.a-search-result {
    margin-left: 5px;
    margin-top: 25px;
}

.hsb-row-container {
    width: 70%;
    flex-wrap: wrap;

}

.hsb-row-container>.hsb-container {
    width: 32%;
    border: 1px solid #c4c4c4;
    /* margin-right: 20px; */
    margin-right: 2%;
}

.hsb-row-container>button {
    width: 21%;
}

.hsb-row-container.not-found {
    width: 40%;
}

.hsb-outer-container>.hsb-container.search-bar {
    height: 25px;
    width: 450px;
    margin-right: 10px;
}

.hsb-row-container.dropdowns {
    display: flex;
    flex-direction: row;
}

.hsb-row-container.dropdowns>button {
    height: 54px;
    margin-top: 20px;
}

.search-banner-mode {
    /* margin-top: 20px; */
    margin-bottom: 30px;
    color: #0055FD !important;
    font-size: 14px;
    /* font-family: HelveticaNeue, arial; */
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    text-align: center;
}


@media only screen and (min-device-width: 350px) and (max-width: 769px) {
    .hsb-row-container {
        flex-wrap: wrap;
        width: 100%;
    }

    .hsb-row-container>.hsb-container, .hsb-row-container>button, .hsb-row-container.not-found {
        width: 100%;
    }

    .hsb-outer-container>.hsb-container.search-bar {
        width: 300px !important;
    }

    .hsb-outer-container.search-bar-outer{
        flex-direction: row;
    }

    .search-banner-mode {
     
    }

}